import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import Link from "@mui/material/Link";
import {
    socialMedia,
    socialMediaLink,
    socialMediaWrapper,
} from "./SocialMedia.module.scss";

const SocialMedia = () => (
    <div className={socialMediaWrapper}>
        <div className={socialMedia}>
            <Link className={socialMediaLink} href="https://instagram.com/victory.beauty?igshid=YmMyMTA2M2Y=">
                <InstagramIcon color="secondary" />
            </Link>
            <Link className={socialMediaLink} href="https://www.facebook.com/victorybeautyliverpool">
                <FacebookIcon color="secondary" />
            </Link>
            <Link className={socialMediaLink} href="mailto:info@victorybeauty.co.uk">
                <EmailIcon color="secondary" />
            </Link>
        </div>
    </div>
);

export default SocialMedia;
