import * as React from "react";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import TreatwellLogo from "../TreatwelLogo/TreatwellLogo";
import { avatar } from "./DialogModal.module.scss";
import GmailLogo from "../GmailLogo/GmailLogo";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useMediaQuery, useTheme } from "@mui/material";
import { useBookingModalContext } from "../../providers/BookingModalProvider";

export const BookingModal = () => {
    const { bookAndCloseModal: handleClose, isOpen } = useBookingModalContext();

    const handleListItemClick = () => {
        handleClose();
    };

    const theme = useTheme();
    const fullWidth = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Dialog
            maxWidth={fullWidth ? "xl" : "sm"}
            fullWidth={true}
            onClose={handleClose}
            open={isOpen}
        >
            <DialogTitle align="center">Booking options</DialogTitle>
            <List>
                <ListItem disablePadding disableGutters>
                    <ListItemButton
                        target="_blank"
                        href="https://widget.treatwell.co.uk/place/409412/menu/"
                        onClick={() => handleListItemClick("treatwell")}
                    >
                        <ListItemAvatar>
                            <Avatar className={avatar}>
                                <TreatwellLogo />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Book via Treatwell (Liverpool Only)"
                            secondary="(Formby coming soon on Treatwell)"
                        />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding disableGutters>
                    <ListItemButton
                        href="mailto:info@victorybeauty.co.uk"
                        onClick={() => handleListItemClick()}
                    >
                        <ListItemAvatar>
                            <Avatar className={avatar}>
                                <GmailLogo />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Book via Email (All Locations)"
                            secondary="info@victorybeauty.co.uk"
                        />
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding disableGutters>
                    <ListItemButton
                        href="tel:+447714337897"
                        onClick={() => handleListItemClick()}
                    >
                        <ListItemAvatar>
                            <Avatar>
                                <PhoneIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Call Us"
                            secondary="07714337897"
                        />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding disableGutters>
                    <ListItemButton
                        href="https://api.whatsapp.com/send?phone=447714337897"
                        onClick={() => handleListItemClick()}
                    >
                        <ListItemAvatar>
                            <Avatar>
                                <WhatsAppIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Message us on WhatsApp"
                            secondary="07714337897"
                        />
                    </ListItemButton>
                </ListItem>
            </List>
        </Dialog>
    );
};
