import React from "react";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Hidden from "@mui/material/Hidden";
import Link from "@mui/material/Link";
import theme from "../../gatsby-theme-material-ui-top-layout/theme";
import ScrollTop from "../ScrollTop/ScrollTop";
import NavBar, { isHomePage } from "../NavBar/NavBar";
import {
    mainContainer,
    mobileContactLinks,
    firstElementGaps,
} from "./Layout.module.scss";
import { DefaultProps } from "../types/types";
import BookButtonMobile from "../BookButtonMobile/BookButtonMobile";
import Footer from "../Footer/Footer";
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";
import { isBrowser } from "../../utils";
import { BookingModal } from "../DialogModal/DialogModal";
import { BookingModalProvider } from "../../providers/BookingModalProvider";

const shouldShowQuickBook = () =>
    isBrowser() &&
    !["/shop"].some(
        (route) => route !== "/" && window.location.href.includes(route)
    );

const shouldShowEmailAndMobile = () =>
    isBrowser() &&
    (window.location.href.endsWith("/contact") ||
        window.location.href.endsWith("/contact/"));

const Layout = ({ children }: DefaultProps<HTMLElement>) => (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <BookingModalProvider>
            <NavBar />
            <Toolbar id="back-to-top-anchor" />
            <main>
                <Container
                    className={mainContainer}
                    maxWidth="xl"
                    disableGutters
                >
                    <div className={firstElementGaps}>
                        {shouldShowEmailAndMobile() ? null : (
                            <div className={mobileContactLinks}>
                                <Link
                                    variant="contactDetails"
                                    href="mailto:info@victorybeauty.co.uk"
                                >
                                    info@victorybeauty.co.uk
                                </Link>
                                <Link
                                    variant="contactDetails"
                                    href="tel:+447714337897"
                                >
                                    07714337897
                                </Link>
                            </div>
                        )}
                        {isHomePage() ? null : <BreadCrumbs />}
                    </div>
                    {children}
                    {shouldShowQuickBook() ? (
                        <Hidden mdUp>
                            <BookButtonMobile />
                        </Hidden>
                    ) : null}
                    <Hidden mdDown>
                        <ScrollTop />
                    </Hidden>
                </Container>
                <BookingModal />
            </main>
            <Footer />
        </BookingModalProvider>
    </ThemeProvider>
);

export default Layout;
